<template>
    <div class="container-fluid">
            <div class="scrlt-y" v-bind:style="'height:'+  (screenHeight-210) +'px;'">
                <div class="card border-0">
                    <div class="accordion mb-3">
                        <div class="accordion-item">
                            <h2 class="accordion-header">
                              <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#filter">
                                <p class="h9 fw-bold m-0">Filter</p>
                              </button>
                            </h2>
                            <div id="filter" class="accordion-collapse collapse show">
                              <div class="accordion-body">
                                <form class="container" id="frmStock" v-on:submit.prevent="showOutstanding" autocomplete="off">
                                    <div class="row align-items-end justify-content-center">
                                        <div class="col-md-7 row">
                                            <div class="col-md-3">
                                                <label class="form-label h9 fw-bold">Period</label>
                                                <input v-model="out.periode" class="form-control" type="text" placeholder="periode">
                                            </div>
                                            <div class="col-md-9">
                                                <label class="form-label h9 fw-bold">Location</label>
                                                <select v-model="out.location" class="form-select" style="width: 100%;" required>
                                                    <option value="" disabled selected>Pilih Location</option>
                                                    <option value="SUB">Surabaya</option>
                                                    <option value="SRG">Semarang</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div class="col-md-4">
                                            <button type="submit" class="btn btn-primary" form="frmStock"><i class="fa fa-search"></i>&nbsp; Show</button>
                                        </div>
                                    </div>
                                </form>
                              </div>
                            </div>
                          </div>
                    </div>
                    <table class="table mb-0 h9 table-hover table-strip table-bordered" id="recstock">
                        <thead>
                            <tr class="h9">
                                <th class="w3-border-right">No SO</th>
                                <th class="w3-border-right">Customer</th>
                                <th class="w3-border-right">Total</th>
                                <th class="w3-border-right">act</th>
                            </tr>
                        </thead>
                    </table>
                </div>
            </div>
            <div id="modal-form" class="modal row mt-4 top-50 start-50 translate-middle ms-md-0 ms-1 modal-lg" style="max-width: 800px;max-height:650px;">
                <div class="modal-dialog modal-dialog-scrollable">
                    <div class="w3-animate-zoom modal-content col-md-3">
                        <div class="modal-header pb-0">
                            <button onclick="document.getElementById('modal-form').style.display='none'" class="btn w3-display-topright btn-lg">&times;</button>
                            <p class="h9 fw-bold"><i class="mdi mdi-file-document-multiple-outline"></i>&nbsp; Detail {{this.so}}</p>
                            <!-- munculkan data:so disini -->
                        </div>
                        <div class="modal-body">
                            <form class="container" id="frmAprove" v-on:submit.prevent="saveData" autocomplete="off">
                                <div class="mb-3">
                                        <!-- <label for="ta_so" class="form-label h9 fw-bold">{{findObjFind('name','ta_so')}} :</label> -->
                                </div>
                            </form>
                            <p class="fw-bold h9">
                                Daftar barang
                            </p>
                            <table class="table table-hover table-responsive" id="barang" style="cursor:pointer">
                                <thead>
                                    <tr class="table-head">
                                        <th class="">No</th>
                                        <th class="">Nama Barang</th>
                                        <th class="">Qty</th>
                                        <th class="">Harga</th>
                                        <th class="">Total</th>
                                    </tr>
                                </thead>
                            </table>
                        </div>
                        </div>
                </div>
            </div>
    </div>
</template>
<script>
/* eslint-disable */
// import Config from '@/conf/Config.js';
import axios from "axios";
import "vue-search-select/dist/VueSearchSelect.css";
import { ModelListSelect } from "vue-search-select";

// import "jquery/dist/jquery.min.js";
import "datatables.net-bs5/js/dataTables.bootstrap5.min.js";
import "datatables.net-bs5/css/dataTables.bootstrap5.min.css";
import "datatables.net-buttons-bs5/js/buttons.bootstrap5"
import "datatables.net-buttons-bs5/css/buttons.bootstrap5.min.css"
import "datatables.net-buttons/js/buttons.html5.js";
import $ from "jquery";

export default {
    // head: {
    //     title: {
    //       inner: 'Main'
    //     }
    // },
    name : 'ItemStock',
    components: {
        ModelListSelect
    },
    data() {
        return {
            screenHeight : 0,
            selectedRow : -1,
            DatePickerFormat: 'YYYY-MM-DD',
            out: {
                periode : '',
                location : '',
                pfunction : 'showstock'
            },
            stocks : [],
            reci: [],
            so:'',
            tab : 1
        }
    },
    methods : {
        showOutstanding(event) {
            let self = this;
            document.getElementById('idLoading').style.display = 'block';
            axios.post("marketing/ApiOutstanding.php", self.out).then(function(response) {
                document.getElementById('idLoading').style.display = 'none';
                self.stocks = response.data.rec;
                $('#recstock').DataTable({
                    destroy: true,
                    responsive: true,
                    scrollX: true,
                    deferRender: true,
                    autoWidth: false,
                    data: self.stocks,
                    columns: [
                        {"data": "so"},
                        {"data": "custs"},
                        {
                            "data": "amt",
                            "render": function(data, type, row, meta) {
                                return self.formatRupiah(data);
                            }
                        },
                        {
                            "data": "sys",
                            "render": function(data, type, row, meta) {
                                return `<button class="edit-button btn btn-success" data-sys="${data}" data-so="${row.so}">Detail</button>`;
                            }
                        }
                    ],
                    columnDefs: [],
                    dom: "<'d-flex dlex-row flex-nowrap mb-3 align-items-center p-0'<'h9 col-md-6'l><'h9 col-md-6'f>>" +
                         "<'col-md-6'B>" +
                         "<'row'<'col-md-12 h9 mb-3'tr>>" +
                         "<'row'<'col-12 col-md-5 h9'i><'col-12 col-md-7'p>>",
                    buttons: [
                        {
                            extend: 'csvHtml5',
                            text: 'Export',
                            className: 'btn btn-primary',
                        }
                    ],
                    initComplete: function() {
                        $('#recstock').on('click', '.edit-button', function() {
                            const sys = $(this).data('sys');
                            const so = $(this).data('so');
                            self.showEdit(sys,so);
                        });
                    }
                });
            });
            event.preventDefault();
            return false;
        },
        showEdit(sys,so) {
            let self = this;
            self.so = so;
            axios.post("marketing/ApiApproved.php", {
                pfunction: 'showitm',
                sys: sys
            }).then(function(response) {
                self.reci = response.data.recz;
                $('#barang').DataTable({
                    destroy: true,
                    responsive: true,
                    scrollX: true,
                    deferRender: true,
                    autoWidth: false,
                    data: self.reci,
                    columns: [
                        {"data": "LineNo"},
                        {"data": "Description"},
                        {"data": "Qty"},
                        {
                            "data": "UnitPrice",
                            "render": function(data, type, row, meta) {
                                return self.formatRupiah(data);
                            }
                        },
                        {
                            "data": "NetAmt",
                            "render": function(data, type, row, meta) {
                                return self.formatRupiah(data);
                            }
                        }
                    ],
                    columnDefs: [],
                    dom: "<'row'<'col-md-12 h9 mb-3'tr>>"
                });
            });
            document.getElementById('modal-form').style.display = 'block';
            setTimeout(function() {
                document.getElementById('ta_so').focus();
            }, 500);
        },
        formatRupiah(amount) {
            let reverse = amount.toString().split('').reverse().join(''),
                ribuan = reverse.match(/\d{1,3}/g);
            return 'Rp ' + ribuan.join('.').split('').reverse().join('');
        },
        setInit() {
            let self = this;
        }
    },
    filters: {
        toRp(value) {
            return `${Intl.NumberFormat("de-DE").format(value)}`;
        },
        pNumber(num) {
            var x = num.replace(/\D/g, '').match(/(\d{0,4})(\d{0,4})(\d{0,4})/);
            return !x[2] ? x[1] : '(' + x[1] + ')-' + x[2] + (x[3] ? '' + x[3] : '');
        }
    },
    watch: {
        // 'moveCenter': function(){
        //     console.log("OK");
        // }
        'tab': function(dt){
            if (dt == 1) {
                $('#recmarketing').DataTable().destroy();
            }else{
                $('#recstock').DataTable().destroy();
            }
        }
    },
    computed :{
        filterByShow(){
            return  this.filters.fields.filter(event => {
                return event.grid == true;
            });
        }
    },
    mounted(){
        this.screenHeight = window.innerHeight;
        this.setInit();
    }
};
</script>